import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ContentModal = ({ title, icon, banner, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (<li>
    <div
      className="list_inner"
      onClick={toggleModal}
      data-aos="fade-right"
      data-aos-duration="1200"
    >
      <div>
        <img className="svg" src={icon} alt="" />
      </div>
      <div className="service_title">
        <h3>{title}</h3>
      </div>
      <div className="learn_more">
        Learn More<span></span>
      </div>
    </div>

    {/* Start Modal Motion Graphy */}
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="custom-modal"
      overlayClassName="custom-overlay"
      closeTimeoutMS={500}
    >
      <div className="beny_tm_modalbox_service">
        <button className="close-modal" onClick={toggleModal}>
          <img src="/img/svg/cancel.svg" alt="close icon" />
        </button>
        <div className="box_inner">
          <div className="description_wrap scrollable">
            <div className="popup_informations">
              <div className="image">
                <div
                  style={{
                    minHeight: "450px"
                  }}
                ></div>
                <div
                  className="main hidden"
                  style={{
                    minHeight: "450px",
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + banner
                    })`,
                  }}
                ></div>
              </div>
              <div className="description" dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </li>);
}

const ServiceAnimation = ({services}) => {
  return (
    <div className="service_list">
      <ul>
        {services.map((service, i) => {
          const properties = {
            title: service.title, 
            icon: service.imgSrc, 
            banner: service.bannerImgSrc, 
            content: service.content
          };
          return <ContentModal {...properties} />
        })}
      </ul>
    </div>
  );
};

export default ServiceAnimation;
