import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";

import {
  FiHome,
  FiUser,
  FiSettings,
  FiGrid,
  FiCast,
  FiPhoneOutgoing,
  FiTrendingUp
} from "react-icons/fi";

const ICONS = {
  FiHome,
  FiUser,
  FiSettings,
  FiGrid,
  FiCast,
  FiPhoneOutgoing,
  FiTrendingUp
};

const Header = ({sections}) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* TOPBAR  */}
      <div className={navbar ? "beny_tm_topbar animate" : "beny_tm_topbar"}>
        <div className="in">
          <div className="topbar_inner">
            <div className="logo">
              <Link to="/">
                <img src="img/logo/dark.png" alt="brand" />
              </Link>
            </div>
            <div className="menu">
              <Scrollspy
                className="anchor_nav"
                items={[
                  "home",
                  "about",
                  "service",
                  "portfolio",
                  "news",
                  "contact",
                ]}
                currentClassName="current"
              >
                { sections.map((section, key) => {
                  return (<li  key={`section_link_${key}`} className={key === 0 ? "current" : ""}>
                    <a href={`#${section.id}`}>
                      <span className="first">{section.title}</span>
                      <span className="second">{section.title}</span>
                    </a>
                  </li>);
                })}                
              </Scrollspy>
            </div>
          </div>
        </div>
      </div>
      {/* /TOPBAR */}

      <div className="mobile-menu-wrapper">
        <Scrollspy
          className="mobile_menu-icon"
          items={["home", "about", "service", "portfolio", "news", "contact"]}
          currentClassName="current"
          offset={-65}
        >
          { sections.map((section, key) => {
            const Icon = ICONS[section.icon] || FiUser;
            return (<li key={`section_${key}`} className={key === 0 ? "current" : ""}>
              <a href={`#${section.id}`}>
                <Icon />
                <span>{section.title}</span>
              </a>
            </li>);
          })}    
        </Scrollspy>
      </div>
      {/* End mobile-menu */}
    </>
  );
};

export default Header;
