import React from "react";

const Footer = () => {
  return (
    <>
      <div className="inner">
        <div className="copy">
          <p>
            &copy; {new Date().getFullYear()} by{" "}
            Under a Tree
            . All rights reserved.
          </p>
          <p style={{ fontSize: "10px" }}>
            We would like to begin by acknowledging the land on which we gather, and which the Region of Peel operates, is part of the Treaty Lands and Territory of the Mississaugas of the Credit. For thousands of years, Indigenous peoples inhabited and cared for this land, and continue to do so today. In particular we acknowledge the territory of the Anishinabek, Huron-Wendat, Haudenosaunee and Ojibway/Chippewa peoples; the land that is home to the Metis; and most recently, the territory of the Mississaugas of the Credit First Nation who are direct descendants of the Mississaugas of the Credit.
            We are grateful to have the opportunity to work on this land, and by doing so, give our respect to its first inhabitants.

          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
