import React from "react";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiExternalLink,
  FiLinkedin,
} from "react-icons/fi";

const socialList = [
  { iconName: <FiExternalLink />, link: "https://www.psychologytoday.com/ca/therapists/kirati-patel-brampton-on/1070309" },
  {
    iconName: <FiLinkedin />,
    link: "https://www.linkedin.com/in/kirati-patel/",
  },
];

const SocialTwo = () => {
  return (
    <ul>
      {socialList.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            {val.iconName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialTwo;
