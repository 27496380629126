import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const Portfolio = ({content}) => {
  const [isOpen, setOpen] = useState({});
  const setModal = (id, value) => {
    setOpen({
      ...isOpen,
      [id]: value 
    })
  }
  const getModal = (id) => {
    if(!isOpen[id]) return false;
    else return true;
  }
  const getURL = (type, videoId) => {
    if(type === "youtube") return `https://www.youtube.com/watch?v=${videoId}`;
    if(type === "vimeo") return `https://player.vimeo.com/video/${videoId}?h=92d8f1bd0a`;
  }
  const videos = content.filter(a => a.type === "video");
  const images = content.filter(a => a.type !== "video");
  return (
    <SimpleReactLightbox>
      <div className="portfolio_list">
        <ul className="gallery_zoom">
          {
            videos.map((video, i) => {
              return (
                <div key={`modal_video_${i}`}>
                  <ModalVideo
                    {...video.video}
                    isOpen={getModal(`${video.video.channel}:${video.video.videoId}`)}
                    onClose={() => setModal(`${video.video.channel}:${video.video.videoId}`, false)}
                  />
                  <li data-aos="fade-right" data-aos-duration="1200">
                    <div className="list_inner video">
                      <a
                        href={getURL(video.video.channel, video.video.videoId)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="title"
                      >
                        <h3>{video.title}</h3>
                        <span>{video.subtitle}</span>
                      </a>
                      <img
                        src={video.image}
                        alt={video.subtitle}
                        onClick={() => setModal(`${video.video.channel}:${video.video.videoId}`, true)}
                      />
                    </div>
                  </li>
                </div>
              )
            })
          }
          <SRLWrapper>
            { images.map((image, key) => {
              const delay = (key + 1) * 100;
              return (<li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay={delay}
              >
                <div className="list_inner">
                  <a
                    className="title"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={image.link}
                  >
                    <h3>{image.title}</h3>
                    <span>{image.subtitle}</span>
                  </a>
  
                  <a href={image.image}>
                    <img src={image.image} alt={image.subtitle} />
                  </a>
                </div>
              </li>);
            }) }
          </SRLWrapper>
        </ul>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
