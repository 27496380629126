import React from "react";
import Header from "../components/header/Header";
import Slider from "../components/slider/SliderAnimation";
import About from "../components/about/AboutAnimation";
import FAQSection from "../components/faq/FAQSection";
import InfoGraphicModal from "../components/info-graphic-modal/InfoGraphicModalAnimation";
import IssueInfoGraphicModal from "../components/info-graphic-modal/IssueInfoGraphicModalAnimation";
import ImageSelector from "../components/image-selector/ImageSelectorAnimation";
import SimpleSlider from "../components/simple-slider/SimpleSlider";
import Blog from "../components/blog/BlogAnimation";
import Contact from "../components/Contact";
import Footer from "../components/footer/Footer";
import Address from "../components/Address";
import { sections, seo } from "../config.json";
import { SuperSEO } from "react-super-seo";

const plugins = {
    About,
    InfoGraphicModal,
    ImageSelector,
    SimpleSlider,
    Blog,
    FAQSection,
    Contact,
    Address,
    IssueInfoGraphicModal
};

const CustomComponent = ({id, className, title, subtitle, content, plugin, aos = false}) => {
    const Plugin = plugins[plugin.type];
    return (
        <div className={className || `beny_tm_${id}`} id={id}>
            <div className="container">
              <div className="beny_tm_title_holder">
                <span>{title}</span>
                <h2>{subtitle}</h2>
                <p>
                  {content}
                </p>
              </div>
              { aos ? <div {...aos}><Plugin {...plugin.properties}/></div> : <Plugin {...plugin.properties}/>}            
            </div>
        </div>
    );
};

const Main = () => {
  document.body.classList.add("light");
  return (
    <div className="home-light">      
      <SuperSEO
        {...seo}
        >
          {(seo.metas || []).map((meta, i)=> <meta key={`meta_${i}`} {...meta}/>)}
        </SuperSEO>
      <Header sections={[{id:"home", title: "Home", icon: "FiHome"}, ...sections, {id: "contact", title: "Contact", icon: "FiPhoneOutgoing"}]}/>
      <Slider />
      {sections.map((section, i) => {
        return <CustomComponent key={`CustomComponent_${i}`} {...section} />
      })}
      <Contact />
      <div className="beny_tm_copyright">
        <div className="container">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Main;
