import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ServiceAnimation = ({ specialities }) => {
  return (
    <div className="service_list">
      <div className="right"
        data-aos="fade-right"
        data-aos-duration="1200"
        data-aos-delay="50">
        <div className="list" style={{"paddingBottom": "18px"}}>
          <h3 className="title">Issues I work with:</h3>
          <ul>
            {specialities.map((speciality, index) => (
              <li key={index}>{speciality}</li>
            ))}
          </ul>
        </div>      
      </div>
    </div>
  );
};

export default ServiceAnimation;
