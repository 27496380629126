import React from "react";

const FAQSection = ({ faqs = [] }) => {
  // Inline styles for spacing
  const questionStyle = {
    marginBottom: '10px', // Adds space below the question
  };

  const answerStyle = {
    marginTop: '0', // Removes any default top margin from the answer paragraph for consistency
    marginBottom: '20px', // Adds more space below the answer for better separation from the next question
    textAlign: 'justify', // Optional: Justifies the text of the answer for a neat look
  };

  return (
    <div className="beny_tm_resume">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item" style={{ marginBottom: '30px' }}> {/* Adds space between each FAQ item */}
          <h3 style={questionStyle}>{faq.question}</h3>
          <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
        </div>
      ))}
    </div>
  );
};

export default FAQSection;