import React, { useEffect } from "react";

const AboutAnimation = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://member.psychologytoday.com/verified-seal.js';
    script.dataset.id = '1070309';
    script.dataset.code = 'aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s=';
    script.dataset.badge = '17';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

//useEffect(() => {
  //  const script = document.createElement('script');
  //  script.type = 'text/javascript';
  //  script.src = 'https://member.psychologytoday.com/verified-seal.js';
  //  script.dataset.id = '1070309';
  //  script.dataset.code = 'aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s=';
  //  script.dataset.badge = '17';
  //  document.body.appendChild(script);
//
  //  return () => {
  //    document.body.removeChild(script);
  //  };
  //}, []);
  
  return (
    <>
      <div className="">
        <div
          className="right"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="50"
        >
          <div className="short">
            <h3>Kirati Patel</h3>
            <h5>
              Registered <span className="theme-color">Psychotherapist</span>
            </h5>
          </div>
          <div className="extra">
            <div className="aboutflex">
              <div className="image">
                <img src={`img/about/10.jpg`} alt="Kirati Patel is a registered psychotherapist who provides collaborative psychotherapy services. She has a Master's in Clinical Psychology and her therapeutic approach is influenced by integrative psychodynamic psychotherapy."></img>
              </div>
              <div className="list">
                <p>
                  Hi, I am Kirati (she/her),
                  a Registered Psychotherapist exploring human experiences.
                  I am dedicated to working with individuals and their different <b>levels of consciousness</b>, creating a space for authentic human connection.
                </p>
                <p>
                  My therapeutic approach is client-centered and trauma-informed,
                  rooted in integrative psychodynamic psychotherapy.
                  My work is guided by my interest in the inner child, biology, and human development.
                  In sessions, we will be reflecting on your habitual ways of thinking and behaving,
                  helping you learn more about how your mind works,
                  and supporting you in <b>embracing your true self</b>—all to <b> improve your functioning,
                    relationship with others and strengthen connection to your self.</b>
                </p>
                <p>
                  I am drawn to this work by my own journey of self-discovery.
                  My therapist self is a medium for reflection,
                  dedicated to assisting you in <b>human embodiment</b>- rediscovering,
                  fostering, and encouraging you to embrace your true self.
                  I believe in the inherent completeness, worthiness,
                  and goodness within each of us.
                  Hence, I focus on co-creating a safe and nurturing space through which you can feel empowered to
                  expand your self-awareness, understand your responses, and <b>rediscover your innate resilience</b>.
                </p>
              </div>
            </div>
              <div className="list">
                <p>
                  A simple way to understand psychotherapy is- to make sense together. Thus, my practice of psychotherapy is collaborative, focusing on developing and maintaining a therapeutic relationship with my clients.
                </p>
                <p>
                  My journey to becoming a psychotherapist began over a decade ago and has been a transformative experience. I completed my Master's in Clinical Psychology and initially felt content with my chosen career path. However, going into an intensive five-year psychotherapy program led me to re-evaluate and shift my focus to the field of psychotherapy. This transition has been a journey of personal and professional growth as I continue to explore my experiences and inner work to enhance my ability to support my clients.
                </p>
              </div>
            <div className="list">
              <p>
                My therapeutic approach is influenced by integrative psychodynamic psychotherapy, and different techniques are interweaved based on the session and the client. Additionally, it is also guided by my interest in human development and my research on the inner child. Having thorough academic training, my therapeutic practice is emotionally, cognitively, biologically, and psychologically informed.
              </p>
              <p>
                Some common goals of psychotherapy are- to recognize and understand the patterns of thoughts or behavior to aid one’s understanding of self, to empower the client to gain awareness and control over their responses, and to uncover their resilience. I aim to listen, reflect, and facilitate empathy, support, and integration with the client.
              </p>
            </div>
            <div className="list">
              <p>
                I am a Registered Psychotherapist (Qualifying), and a member of CRPO and CAPT. I have studied for Bachelor’s in Psychology, Masters in Clinical Psychology, and Diploma in Psychotherapy (OPC).
              </p>
              <p>
                With training and knowledge of both clinical and psychotherapeutic aspects of mental health, I am equipped to help clients who struggle with- mood imbalances, grief, loss, traumatic events, intergenerational trauma, feeling distant from oneself, feeling unseen/unheard by others, relational difficulties, and conflicts. I value diversity; my practice is 2SLGBTQ+ and BIPOC inclusive.
                <a href="https://www.psychologytoday.com/profile/1070309" className="sx-verified-seal"></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutAnimation;
